


.main-logo img {
  width:200px
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1000; /* Adjust z-index as needed */
    box-shadow: 0px 8px 30px rgba(27,58,146,.1);
    background: #fff;
  }
.downloadApp {
  width: 65%
}

.grey_text {
  color: #666666;
}


.process_wrapper {
  margin-top: 5rem;
}

.process_wrapper h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 25px;
}

.process_wrapper p {
  font-size: 14px;
}

.process_img img {
  width: 50px;
  height: 50px;
  margin: 10px;
}

.blog_wrapper {
  margin-top: 5rem;
  padding-top: 100px;
  padding-bottom: 150px;
  /* background: linear-gradient(90deg, #ffffff, rgba(var(--main-color-rgba), 0.2)); */
  background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgba(var(--main-color-rgba), 0.2) 80%);
}

.blog_wrapper .card a {
  /* color: var(--main-color); */
  /* font-weight: 600; */
  color: #676767;
}

.blog_wrapper .card-content {
  padding: 15px;
}

.blog_wrapper .blog_img img {
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

}

.blog_wrapper h4 {
  font-size: 1.2rem;
  font-weight: 600;
}

.blog_wrapper p {
  font-size: 0.9rem;
}

.social_nav {
  display: flex;
  gap: 10px;
}


.social_nav .social_btn {
  display: flex;
  align-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  font-size: 22px;
  transition: all ease-in-out .3s;
}

.social_nav .social_btn:hover {
  background-color: var(--main-color);
  color: #fff;
}
.footer-bg {
  background-color: var(--black-color-600);
  color: var(--white-color);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer_row h6 {
  font-weight: 600;
  margin-bottom: 20px;
}

.footer_row ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer_row ul li a {
  text-decoration: none;
  color: var(--dark-gray-color-500);
}

.footer_row ul li a:hover {
  color: var(--main-color);
}

.footer-bottom {
  padding: 10px;
  background-color: var(--black-color-500);
  color: rgba(var(--white-color-rgb), 0.8);
}

.divider-bottom {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.cta_block {
  /* background: var(--light-gray-color); */
  background: linear-gradient(109.6deg, rgba(var(--main-color-rgba), 0.4) 11.2%, rgba(var(--main-color-rgba), 0.32) 100.2%);
  /* position: relative;  */
  /* height: 300px;  */
  /* overflow: hidden;  */
  /* border-radius: 10px; */
  /* margin: 10px auto; */
}

.cta_block .cta_content {
  padding-top: 20px;
}

.cta_block h3 {
  color: var(--text-color);
  font-size: 2.5rem;
  font-weight: 600;
}

.cta_block p {
  color: rgba(var(--subtext-color), 0.8);
  font-size: 1rem;
}

.cta_block .cta_img {
  margin-top: -50px;

}

.faq_wrapper {
  padding: 100px 0;
}

.faq_wrapper h2 {
  font-size: 2rem;
}

.faq_wrapper .nav-tabs .nav-link.active {
  font-weight: 600s;
}

.faq_wrapper .nav-tabs .nav-link {
  color: #000;
}

.faq_wrapper button:focus:not(:focus-visible) {
  /* outline: 0; */
  box-shadow: none;
  background-color: transparent !important;
}

.faq_wrapper .accordion-item {

  border-bottom: 1px solid #ccc !important;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: transparent !important;
  box-shadow: none !important;
  font-weight: 600;
  border-radius: 0!important;
}

.faq_wrapper .accordion-item {
  border: 0
}

.faq_wrapper .accordion-button {
  font-weight: 600;
}

.accordion-item:last-of-type 
.accordion-button.collapsed {
  border-bottom-right-radius:0!important;
  border-bottom-left-radius: 0!important
}



.press_heading,
.investor_wrapper h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 2rem;
}
.press_container {
  padding-bottom: 100px;
}
.press_wrapper {
  /* width: 1140px; */
  background-color: #eff2f5;
  padding: 40px 20px;
  border-radius: 12px;
  
}

.highlighting {
  color: var(--main-color);
  font-weight: bold;
  /* border-radius: 10px;
  padding: 0 20px; */
}

.pro-col-info {
  padding: 35px 35px 22px 35px;
  height: 100%;
  border: 2px dashed #ddd;
  /* -webkit-box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.1);
  box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.1); */
  border-radius: 10px;
  text-align: center;
}

.pro-col-info:hover {
  /* -webkit-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1); */
background-color: rgba(var(--main-color-rgba), 0.5);
}

.pro_img {
  margin-bottom: 20px;
}

a.prod_link {
  color: var(--main-color);
  text-decoration: none;
}

.pro_heading {
  font-weight: 600;
  color: var(--heading-color);
}

.featured_icon {
  width: 3.5rem;
  height: 3.5rem;
  color: var(--main-color);
  background-color: rgba(var(--main-color-rgb), .2);
  border: 3px solid var(--main-color);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.featured_icon  svg{
 font-size: 1.8rem;
  color: var(--black-color);
  background-color: transparent;
}

