/* HeroSection.css */

.main-content-fold {
    background: linear-gradient(90deg, #ffffff, rgba(var(--main-color-rgba), 0.2));
    /* background: linear-gradient(90deg, #ffffff, #f0f0f0); */
    align-items: center;
    padding: 100px 0 60px;
}

.main-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
}

.hero-text {
    flex: 1;
}

.main-content img {
    margin-top: 50px;
}

.hero-text h1 {
    font-size: 2.9rem;
    color: #000;
    margin-bottom: 9px;
    font-weight: 600;
}

.hero-text p {
    font-size: 1.2rem;
    color: #666666;
}

.hero_stats {
    margin: 55px 0
}

.hero_number {
    color: #2979ff;
    margin-top: 0;
    margin-bottom: .5rem;
    font-size: 2.75rem;
    font-weight: 600;
    line-height: 1.2;
}

.hero_text {
    color: #000;
    font-weight: 500;
}

.img_backdrop {
    display: inline-block;

    /* width:30px;
    height:30px;   
    box-shadow: 0 5px 13px #3e454e33 !important;
    display: flex;
    align-content: center;
    justify-content: center; */}

.img_backdrop img {
    width: 45px;
    /* height:45px; */
    box-shadow: 0 5px 13px #3e454e33 !important;
    border-radius: 50px;
    background-color: #fff;
    margin: 5px;
    padding: 5px;
}

@media (max-width: 480px) {

    .main-content-fold {
        padding: 60px 0 20px !important;

    }
}